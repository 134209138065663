
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "./InformationDDC.vue";
import {BleedingDisorder} from "../../models/reports/HemophiliavWDbyGender/bleedingdisorder";
import {DiseaseTypeResult} from "../../models/reports/HemophiliavWDbyGender/hemophiliavWDbyGederData";
import formatUtil from "../../mixins/roundValues";
import {formatFinancialAmounts} from '../../utils/format'
import highlightReports from "../../mixins/highlightReports";

export default Vue.extend({
  name: "HemophiliavWDbyGender",
  components: {InformationDDC},
  mixins: [formatUtil, highlightReports],
  data: () => ({
    data: [],
    formatFinancialAmounts,
    bleedingDisorderSelected: [],
    bleedingDisorders: [],
    genders: [],
    isLoading: false,
    payload: {
      year: null,
      diseaseType: [] as number[],
    },
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.hemophiliavWDbyGender");
    },
  },
  methods: {
    getFieldByGender(gender: string, diseaseTypeResult: DiseaseTypeResult) {
      switch (gender) {
        case "Male": {
          return [
            diseaseTypeResult.current_Male,
            diseaseTypeResult.previous_Male,
            diseaseTypeResult.male_Count_Perc_Diff,
            diseaseTypeResult.male_Proportion_Perc_Diff,
          ];
        }
        case "Female": {
          return [
            diseaseTypeResult.current_Female,
            diseaseTypeResult.previous_Female,
            diseaseTypeResult.female_Count_Perc_Diff,
            diseaseTypeResult.female_Proportion_Perc_Diff,
          ];
        }
        case "Unknown": {
          return [
            diseaseTypeResult.current_Unknown,
            diseaseTypeResult.previous_Unknown,
            diseaseTypeResult.unknown_Count_Perc_Diff,
            diseaseTypeResult.unknown_Proportion_Perc_Diff,
          ];
        }
      }
    },
    loadGenders() {
      axios
          .get(`Report/DDC/DiseaseGender/genderType`)
          .then((res) => {
            this.genders = res.data;
          })
          .catch((err) => console.log(err));
    },
    loadFilters() {
      axios
          .get(`Report/DDC/DiseaseGender/filter`)
          .then((res) => {
            this.bleedingDisorders = res.data;
          })
          .catch((err) => console.log(err));
    },
    getData() {
      this.isLoading = true;
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
          function (item: BleedingDisorder) {
            return item.id;
          }
      );
      this.payload.diseaseType = arrayIDDisorders;
      this.payload.year = this.yearSelected;
      axios
          .post("report/DDC/DiseaseGender", this.payload)
          .then((res: any) => {
            this.data = res.data;
            this.isLoading = false;
          })
          .catch((err) => console.log(err));
    },
    exportData() {
      const arrayIDDisorders: number[] = this.bleedingDisorderSelected.map(
          function (item: BleedingDisorder) {
            return item.id;
          }
      );
      axios
          .post(
              `report/ddc/DiseaseGender/export`,
              {
                diseaseType: arrayIDDisorders,
                year: this.yearSelected,
              },
              {responseType: "blob"}
          )
          .then((res) => {
            const fileName =
                `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
            this.downloadExcel(fileName, res.data);
          })
          .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], {type: "application/xlsx"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.loadFilters();
    this.loadGenders();
  },
});
